/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-12-31 09:51:59
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-03-28 14:33:32
 */
export default {
  selectType: '/course/product/attr/category/cert/options',
  certificateList: '/course/supply/cert/page',
  createdCertificate: '/course/supply/cert/create',
  certificatelExport: '/course/supply/cert/export',
  attrSelect: '/course/product/attr/category/cert/list',
  delCertificate: '/course/supply/cert/remove',
  certificateDetail: '/course/supply/cert/info',
  searchAgentList: '/course/supply/distributor/query',
  searchStaffList: '/course/supply/distributor/staff/query',
  addAgent: '/course/supply/distributor/add',
  deleteAgent: '/course/supply/distributor/delete',
  exportAgentList: '/course/supply/distributor/export',
  editAgentInfo: '/course/supply/distributor/edit',
  agentDetail: '/course/supply/distributor/base/query',
  agentOrderInfo: '/course/supply/distributor/order/query',
  agentOrderExport: '/course/supply/distributor/order/export',
  beforeDelCertificate: '/course/supply/cert/remove/check',
  signUpList: '/course/supply/cert/sign/record/page',
  createExamCard: '/course/supply/user/cert/admission/ticket/create',
  editExamCard: '/course/supply/user/cert/admission/ticket/edit',
  lookExamCard: '/course/supply/user/cert/admission/ticket/query',
  examine: '/course/supply/cert/sign/record/review',
  uploadData: '/course/supply/cert/sign/record/upload/info',
  dataDetail: '/course/supply/cert/sign/record/detail',
  examList: '/course/supply/cert/exam/record/list',
  importResults: '/course/supply/cert/exam/record/download/excel/template',
  uploadResults: '/course/supply/cert/exam/record/import/excel',
  productSkuList: '/course/supply/cert/exam/record/product/sku/list',
  signUpRecordExport: '/course/supply/cert/sign/record/detail/export',
  signUpDataExport: '/course/supply/cert/sign/record/export',

  classCertQuery: '/course/identify/class/cert/query', // 证书班级查询
  classCertExport: '/course/identify/class/cert/export', // 证书班级导出
  classCertDelete: '/course/identify/class/cert/delete', // 证书班级删除
  classCertBaseEdit: '/course/identify/class/cert/base/edit', // 证书班级基本信息编辑
  classCertBaseInfo: '/course/identify/class/cert/base/query', // 证书班级基本信息查询
  classCertCreate: '/course/identify/class/cert/create', // 证书班级添加
  classCertStudent: '/course/identify/class/cert/student/query', // 证书班级学员查询
  classCertSignQuery: '/course/identify/class/cert/sign/query', // 证书班级报名信息查询
  classCertStudentAdd: '/course/identify/class/cert/student/batch/add', // 批量添加学员
  classCertStudentDelete: '/course/identify/class/cert/student/batch/delete', // 批量删除学员
  classCertCourse: '/course/identify/class/cert/course/query', // 查询证书班级绑定课程
  classCertCourseDelete: '/course/identify/class/cert/course/delete', // 批量删除课程
  classCertCourseShop: '/course/manage/courses/cert/shoppingMall', // 开班-证书课程商城
  classCertCourseShopAdd: '/course/identify/class/cert/course/shop/add', // 通过课程商城加课
  classCertCoursePacket: '/course/identify/class/cert/packet/query', // 课程包查询
  classCertCoursePacketAdd: '/course/identify/class/cert/course/packet/add', // 通过课程包加课
  certOrgQuery: '/course/supply/cert/org/query', // 查询企业下证书
  classCertCourseQuestionStore: '/course/identify/class/cert/course/question/store/query', // 证书班级查询题库列表
  classCertCourseQuestionStoreAdd: '/course/identify/class/cert/course/store/bound', // 批量绑定题库
  classCertCourseStore: '/course/identify/class/cert/course/class/store/query', // 查询证书班级绑定题库信息
  classCertPublish: '/course/identify/class/cert/publish', // 证书班级发布
  classCertCourseStoreDelete: '/course/identify/class/cert/course/store/delete', // 删除题库
  classCertStaff: '/course/identify/class/cert/staff/query', // 查询教务
  classCertAnnouncementAdd: '/course/identify/class/cert/announcement/add', // 证书班级公告新增
  classCertAnnouncement: '/course/identify/class/cert/announcement/query', // 证书班级公告
  classCertAnnouncementDelete: '/course/identify/class/cert/announcement/delete', // 证书班级公告
  classCertAnnouncementDetail: '/course/identify/class/cert/announcement/detail/query', // 证书班级公告
  classCertAnnouncementEdit: '/course/identify/class/cert/announcement/edit', // 证书班级公告编辑
  certAttrProduct: '/course/supply/cert/attrProduct', // 类目下的证书商品
  certProductList: '/course/identify/class/cert/org/cert/product/list',
  selectCertList: '/course/identify/class/cert/cert/category/list',
  selectOrgList: '/course/identify/class/cert/org/cert/list',
  channelList: '/course/identify/class/cert/org/cert/display/channel/list',
  submitChannel: '/course/identify/class/cert/configure/display/channel',
  expressPage: '/course/supply/user/cert/express/page',
  expressExport: '/course/supply/user/cert/express/export',
  expressCode: '/course/supply/user/cert/express/edit/express/code',
  expressPost: '/course/supply/user/cert/express/post',
  expressEdit: '/course/supply/user/cert/express/edit',
  courseTrainTypeUpdate: '/course/identify/class/cert/course/train/type/update',
}
