<template>
  <div>
    <el-dialog
      :title="diongTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-input
        v-model="expressCode"
        placeholder="请输入快递单号"
        oninput="value=value.replace(/[^\w\.\/]/ig,'')"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editexpressCode">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="邮寄信息"
      :visible.sync="dialogVisible1"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="diongBox">收件人：{{ form.receiverName }}</div>
      <div class="diongBox">收件人电话：{{ form.receiverMobile }}</div>
      <div class="diongBox">收件人地区：{{ form.receiverLocation }}</div>
      <div class="diongBox">详细地址：{{ form.address }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible1 = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="编辑收件地址"
      :visible.sync="dialogVisible2"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="收件人姓名" prop="receiverName">
          <el-input v-model="form.receiverName" type="text"></el-input>
        </el-form-item>
        <el-form-item label="收件人手机号" prop="receiverMobile">
          <el-input
            v-model="form.receiverMobile"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="收件人地址" prop="receiverLocation">
          <el-cascader
            ref="myCascader"
            v-model="form.receiverLocation"
            :options="totalCity"
            :props="{
              value: 'label',
            }"
            placeholder="请选择邮寄地址"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="form.address" type="textarea" rows="5"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">确定</el-button>
          <el-button @click="dialogVisible2 = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <table-list
      class="tableList"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="list"
      :title="tableTitle"
      :tabs-list="tabsList"
      :options="{ selection: true, operatesWidth: 70 }"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>

<script>
//表格tabs
const tabsList = [
  {
    prop: 'status',
    activeValue: '',
    children: [
      { value: false, label: '待邮寄' },
      { value: true, label: '已邮寄' },
    ],
  },
]
//表格标题
const tableTitle = '证书邮寄列表'
const btns = _this => [
  {
    label: '导出',
    method: _this.handleExport,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '快递单号',
    prop: 'expressCode',
  },
  {
    label: '收件人姓名',
    prop: 'receiverName',
  },
  {
    label: '收件人手机号',
    prop: 'receiverMobile',
  },
  {
    placeholder: '状态',
    type: 'select',
    prop: 'status',
    children: [
      { value: false, label: '待邮寄' },
      { value: true, label: '已邮寄' },
    ],
  },
  {
    label: '开始时间',
    prop: 'time',
    type: 'picker',
    pickerDate: _this.pickerDate,
  },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'signUserName',
    label: '报名人姓名',
    minWidth: '76px',
  },
  {
    prop: 'certName',
    label: '报名证书',
    minWidth: '134px',
  },
  {
    prop: 'receiverName',
    label: '收件人姓名',
    minWidth: '146px',
  },
  {
    prop: 'receiverMobile',
    label: '收件人手机号',
    minWidth: '136px',
  },
  {
    prop: 'provinceName',
    label: '收件人地区',
    minWidth: '150px',
    formatter: row => {
      return row.provinceName + row.cityName + row.districtName
    },
  },
  {
    prop: 'postTime',
    label: '邮寄时间',
    minWidth: '150px',
    sortdata: 1,
  },
  {
    prop: 'expressCode',
    label: '快递单号',
    minWidth: '150px',
    render: (h, { row }) => {
      if (row.expressCode == '') {
        return ''
      } else {
        return h('div', null, [
          h('span', row.expressCode),
          h('span', null, [
            h(
              'i',
              {
                class: 'el-icon-edit-outline',
                style: 'margin-left: 5px; cursor: pointer;color:#FF7B33',
                on: {
                  click: async v => {
                    await _this.handleOpen(v, row)
                  },
                },
              },
              '',
            ),
          ]),
        ])
      }
    },
  },
  {
    prop: 'status',
    label: '状态',
    minWidth: '76px',
    formatter: row => {
      if (row.status == false) {
        return '待邮寄'
      } else if (row.status == true) {
        return '已邮寄'
      }
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleAdd,
    isShow: row => row.status == false,
  },
  {
    label: '邮寄信息',
    method: _this.handleDetail,
  },
  {
    label: '确认邮寄',
    method: _this.handleOK,
    isShow: row => row.status == false,
  },
]
import total from '../../../assets/address/city'
import TableList from '@/components/TableList'
import {
  expressExport,
  expressPage,
  expressCode,
  expressPost,
  expressEdit,
} from '@/api/certificate'
import to from 'await-to'
import totalCity from '@/assets/address/totalCity'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      totalCity,
      rules: {
        receiverName: [{ required: true, message: '请输入收件人姓名', trigger: 'blur' }],
        receiverMobile: [{ required: true, message: '请输入收件人手机号', trigger: 'blur' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        receiverLocation: [{ required: true, message: '请选择收件人地址', trigger: 'change' }],
      },
      pickerDate: {
        onPick: ({ minDate }) => {
          this.pickerMinDate = minDate.getTime()
        },
        disabledDate: time => {
          const year = 365 * 24 * 3600 * 1000
          let oneYearLater = this.pickerMinDate + year
          return time.getTime() > oneYearLater //注意是||不是&&
        },
      },
      tableTitle,
      btns,
      searchForm,
      columns,
      operates,
      tabsList,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      total,
      queryParams: {},
      select: [],
      list: [],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      expressCode: '',
      userCertExpressId: '',
      diongTitle: '',
      form: {
        userCertExpressId: '',
        receiverName: '',
        receiverMobile: '',
        receiverLocation: '',
        address: '',
      },
    }
  },
  created() {
    this.expressPage()
  },
  methods: {
    async expressPage() {
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(expressPage({ current, size, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      if (queryParams.time) {
        queryParams.postBeginTime = queryParams.time[0]
        queryParams.postEndTime = queryParams.time[1].split(' ')[0] + ' 23:59:59'
      } else {
        queryParams.postBeginTime = ''
        queryParams.postEndTime = ''
      }
      this.queryParams = queryParams
      this.expressPage()
    },
    handleSelectionChange(val) {
      this.select = val
    },
    handleAdd(row) {
      this.dialogVisible2 = true
      this.form.userCertExpressId = row.userCertExpressId
      this.form.receiverName = row.receiverName
      this.form.receiverMobile = row.receiverMobile
      this.form.receiverLocation = [row.provinceName, row.cityName, row.districtName]
      this.form.address = row.address
    },
    handleDetail(row) {
      this.dialogVisible1 = true
      this.form.receiverName = row.receiverName
      this.form.receiverMobile = row.receiverMobile
      this.form.receiverLocation = row.provinceName + row.cityName + row.districtName
      this.form.address = row.address
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.form.provinceName = this.form.receiverLocation[0]
          this.form.cityName = this.form.receiverLocation[1]
          this.form.districtName = this.form.receiverLocation[2]
          const [, err] = await to(expressEdit(this.form))
          if (err) return this.$message({ type: 'error', message: err.msg })
          this.$message.success('编辑成功')
          this.dialogVisible2 = false
          this.expressPage()
        } else {
          return false
        }
      })
    },
    async handleOK(row) {
      this.diongTitle = '确认邮寄'
      this.userCertExpressId = row.userCertExpressId
      this.dialogVisible = true
    },
    async handleOpen(v, row) {
      this.diongTitle = '编辑快递单号'
      this.dialogVisible = true
      this.expressCode = row.expressCode
      this.userCertExpressId = row.userCertExpressId
    },
    async editexpressCode() {
      if (this.expressCode == '') return this.$message.warning('请填写快递单号')
      if (this.diongTitle == '确认邮寄') {
        const [, errs] = await to(expressPost({ userCertExpressId: this.userCertExpressId }))
        if (errs) return this.$message.warning(err.msg)
        const [, err] = await to(
          expressCode({ expressCode: this.expressCode, userCertExpressId: this.userCertExpressId }),
        )
        if (err) return this.$message.warning(err.msg)
        this.$message.success('成功')
        this.expressCode = ''
        this.dialogVisible = false
        this.expressPage()
      } else {
        const [, err] = await to(
          expressCode({ expressCode: this.expressCode, userCertExpressId: this.userCertExpressId }),
        )
        if (err) return this.$message.warning(err.msg)
        this.$message.success('成功')
        this.expressCode = ''
        this.dialogVisible = false
        this.expressPage()
      }
    },
    async handleExport() {
      let certIdList = []
      this.select.map(v => certIdList.push(v.userCertExpressId))
      const [res, err] = await to(
        expressExport({ ...this.queryParams, userCertExpressIds: certIdList }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '证书邮寄列表.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },

    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.expressPage()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.expressPage()
    },
  },
}
</script>

<style scoped lang="scss">
.diongBox {
  margin-bottom: 10px;
}
</style>
