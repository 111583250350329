/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-12-31 09:51:59
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-03-28 14:33:41
 */
import config from './config/certificate'
import axios from '@/utils/request'
export const centerList = params => axios.post(config.centerList, params)
export const selectType = params => axios.post(config.selectType, params)
export const certificateList = params => axios.post(config.certificateList, params)
export const createdCertificate = params => axios.post(config.createdCertificate, params)
export const certificatelExport = params =>
  axios.post(config.certificatelExport, params, { responseType: 'blob' })
export const attrSelect = params => axios.post(config.attrSelect, params)
export const delCertificate = params => axios.post(config.delCertificate, params)
export const certificateDetail = params => axios.post(config.certificateDetail, params)
export const searchAgentList = params => axios.post(config.searchAgentList, params)
export const searchStaffList = params => axios.post(config.searchStaffList, params)
export const addAgent = params => axios.post(config.addAgent, params)
export const deleteAgent = params => axios.post(config.deleteAgent, params)
export const exportAgentList = params =>
  axios.post(config.exportAgentList, params, { responseType: 'blob' })
export const editAgentInfo = params => axios.post(config.editAgentInfo, params)
export const agentDetail = params => axios.post(config.agentDetail, params)
export const agentOrderInfo = params => axios.post(config.agentOrderInfo, params)
export const agentOrderExport = params =>
  axios.post(config.agentOrderExport, params, { responseType: 'blob' })
export const beforeDelCertificate = params => axios.post(config.beforeDelCertificate, params)
export const signUpList = params => axios.post(config.signUpList, params)
export const createExamCard = params => axios.post(config.createExamCard, params)
export const editExamCard = params => axios.post(config.editExamCard, params)
export const lookExamCard = params => axios.post(config.lookExamCard, params)
export const examine = params => axios.post(config.examine, params)
export const uploadData = params => axios.post(config.uploadData, params)
export const dataDetail = params => axios.post(config.dataDetail, params)
export const examList = params => axios.post(config.examList, params)
export const importResults = params =>
  axios.post(config.importResults, params, { responseType: 'blob' })
export const uploadResults = params => axios.post(config.uploadResults, params)
export const productSkuList = params => axios.post(config.productSkuList, params)
export const signUpRecordExport = params =>
  axios.post(config.signUpRecordExport, params, { responseType: 'blob' })
export const signUpDataExport = params =>
  axios.post(config.signUpDataExport, params, { responseType: 'blob' })

export const classCertQuery = params => axios.post(config.classCertQuery, params)
export const classCertExport = params =>
  axios.post(config.classCertExport, params, { responseType: 'blob' })
export const classCertDelete = params => axios.post(config.classCertDelete, params)
export const classCertBaseEdit = params => axios.post(config.classCertBaseEdit, params)
export const classCertBaseInfo = params => axios.post(config.classCertBaseInfo, params)
export const classCertCreate = params => axios.post(config.classCertCreate, params)
export const classCertStudent = params => axios.post(config.classCertStudent, params)
export const classCertSignQuery = params => axios.post(config.classCertSignQuery, params)
export const classCertStudentAdd = params => axios.post(config.classCertStudentAdd, params)
export const classCertStudentDelete = params => axios.post(config.classCertStudentDelete, params)
export const classCertCourse = params => axios.post(config.classCertCourse, params)
export const classCertCourseDelete = params => axios.post(config.classCertCourseDelete, params)
export const classCertCourseShop = params => axios.post(config.classCertCourseShop, params)
export const classCertCourseShopAdd = params => axios.post(config.classCertCourseShopAdd, params)
export const classCertCoursePacket = params => axios.post(config.classCertCoursePacket, params)
export const classCertCoursePacketAdd = params =>
  axios.post(config.classCertCoursePacketAdd, params)
export const certOrgQuery = params => axios.post(config.certOrgQuery, params)
export const classCertCourseQuestionStore = params =>
  axios.post(config.classCertCourseQuestionStore, params)
export const classCertCourseQuestionStoreAdd = params =>
  axios.post(config.classCertCourseQuestionStoreAdd, params)
export const classCertCourseStore = params => axios.post(config.classCertCourseStore, params)
export const classCertPublish = params => axios.post(config.classCertPublish, params)
export const classCertCourseStoreDelete = params =>
  axios.post(config.classCertCourseStoreDelete, params)
export const classCertStaff = params => axios.post(config.classCertStaff, params)
export const classCertAnnouncementAdd = params =>
  axios.post(config.classCertAnnouncementAdd, params)
export const classCertAnnouncement = params => axios.post(config.classCertAnnouncement, params)
export const classCertAnnouncementDelete = params =>
  axios.post(config.classCertAnnouncementDelete, params)
export const classCertAnnouncementDetail = params =>
  axios.post(config.classCertAnnouncementDetail, params)
export const classCertAnnouncementEdit = params =>
  axios.post(config.classCertAnnouncementEdit, params)
export const certAttrProduct = params => axios.post(config.certAttrProduct, params)
export const certProductList = params => axios.post(config.certProductList, params)
export const selectCertList = params => axios.post(config.selectCertList, params)
export const selectOrgList = params => axios.post(config.selectOrgList, params)
export const channelList = params => axios.post(config.channelList, params)
export const submitChannel = params => axios.post(config.submitChannel, params)
export const expressPage = params => axios.post(config.expressPage, params)
export const expressCode = params => axios.post(config.expressCode, params)
export const expressPost = params => axios.post(config.expressPost, params)
export const expressEdit = params => axios.post(config.expressEdit, params)

export const expressExport = params =>
  axios.post(config.expressExport, params, { responseType: 'blob' })
export const courseTrainTypeUpdate = params => axios.post(config.courseTrainTypeUpdate, params)
